<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'5'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>用户管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>用户列表</span>
				</div>
				<div class="left-list-item" @click="leftClick('1')">
					<span>用户权限</span>
				</div>
				<!-- <div class="left-list-item" @click="leftClick('1')">
          <span>提现管理</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>余额明细</span>
        </div> -->
			</div>
			<div class="right-next">
				<div class="title">
					<span>用户列表</span>
				</div>
				<div class="date-search">
					<el-button type="primary" @click="userAdd">添加用户</el-button>
					<div>
						<el-select v-model="value" placeholder="选择公司筛选" clearable>
							<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
						<el-button icon="el-icon-search" class="searchBtn" type="primary" @click="selectBtn">搜索
						</el-button>
					</div>
				</div>
				<div class="mt-20">
					<el-table :data="tableData.data" border style="width: 100%">
						<el-table-column prop="id" label="ID" width="60" align="center">
						</el-table-column>
						<el-table-column prop="phone" label="用户登录账号" width="130" align="center">
						</el-table-column>
						<el-table-column prop="nickname" label="用户昵称" width="130" align="center">
						</el-table-column>
						<el-table-column prop="phone" label="手机号" width="130" align="center">
						</el-table-column>
						<el-table-column prop="company.name" label="所属公司" width="180" align="center">
						</el-table-column>
						<el-table-column prop="department" label="所属部门" width="100" align="center">
						</el-table-column>
						<el-table-column prop="date" label="角色" width="150" align="center">
							<template slot-scope="scope">
								<span v-for="(item,index) in scope.row.role" :key="index">
									{{item.name}}
									<span v-if="(index+1) < scope.row.role.length">、</span>
								</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑
								</el-button>
								<el-button size="mini" type="primary" @click="setRole(scope.$index, scope.row)">权限
								</el-button>
								<el-button size="mini" type="danger" @click="disableUser(scope.$index, scope.row)">删除
								</el-button>
								<el-button size="mini" type="warning" @click="updatePass(scope.$index, scope.row)">修改密码
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="bottom-paging">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="page" :page-sizes="[5, 10, 20, 30, 100]" :page-size="num"
						layout="total, prev, pager, next, sizes" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog title="修改密码" :visible.sync="centerDialogVisible" width="500px" center>
			<div class="form_item">
				<span class="bit"><span>*</span>密码：</span>
				<el-input type="password" v-model="password" autocomplete="off"></el-input>
			</div>
			<div class="form_item">
				<span class="bit"><span>*</span>确认密码：</span>
				<el-input type="password" v-model="confirm_password" autocomplete="off"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="passSubmit">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="设置权限" :visible.sync="centerDialogVisible2" width="500px" center>
			<div class="form_item">
				<el-checkbox-group v-model="rolesType">
					<el-checkbox v-for="(item,index) in RolesList" :label="item.id" :key="index">{{item.name}}</el-checkbox>
				</el-checkbox-group>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible2 = false">取 消</el-button>
				<el-button type="primary" @click="roleUpdate">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				rolesType:[],
				centerDialogVisible: false,
				centerDialogVisible2:false,
				tableData: [],
				isCollapse: false,
				inpuValue: "",
				inputPhone: '',
				page: 1,
				num: 10,
				options: [],
				value: '',
				user_id: '',
				password: '',
				confirm_password: '',
				RolesList:[],
			};
		},
		created() {
			this.getList();
			this.getCompanylist();
			this.getRolesList();
		},
		methods: {
			roleUpdate(){
				this.$post('front/access/user_add_role',{
					user_id:this.user_id,
					role_id:this.rolesType
				}).then(res =>{
					if (res.data.status == 200) {
						this.$notify({
							title: '提示',
							message: '操作成功',
							type: 'success'
						});
						this.reload();
					} else {
						this.$notify({
							title: '提示',
							message: res.data.message,
							type: 'error'
						});
					}
				})
			},
			//获取角色列表
			getRolesList(){
				this.$get('front/access/get_role', {
					page: this.currentPage,
					num: 1000
				}).then(res => {
					// console.log(res);
					this.RolesList = res.data.data.data;
				})
			},
			setRole(index, row){
				this.rolesType = [];
				this.user_id = row.id;
				for (var i = 0; i < row.role.length; i++) {
					this.rolesType.push(row.role[i].id);
				}
				this.centerDialogVisible2 = true;
			},
			passSubmit() {
				if (this.password == '') {
					this.$notify({
						title: '提示',
						message: '密码不可为空！',
						type: 'error'
					});
					return false;
				}
				if(this.confirm_password != this.password){
					this.$notify({
						title: '提示',
						message: '俩次密码不一致！',
						type: 'error'
					});
					return false;
				}
				this.$post('reset_pwd/front_user',{
					id:this.user_id,
					password:this.password
				}).then(res =>{
					if (res.data.status == 200) {
						this.$notify({
							title: '提示',
							message: '操作成功',
							type: 'success'
						});
						this.reload();
					} else {
						this.$notify({
							title: '提示',
							message: res.data.message,
							type: 'error'
						});
					}
				})
			},
			getCompanylist() {
				this.$get('company/get_list', {}).then(res => {
					this.options = res.data.data.data;
				})
			},
			userAdd() {
				this.$router.push('/userEdit');
			},
			selectBtn() {
				this.page = 1;
				this.getList();
			},
			getList() {
				this.$get("front_user/get_list", {
					page: this.page,
					num: this.num,
					company_id: this.value
				}).then(res => {
					this.tableData = res.data.data;
					console.log(res);
				})
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/user") {
					this.$router.push("/user");
				} else if (index == 1 && this.$route.path != "/userRole") {
					this.$router.push("/userRole");
				} else if (index == 2 && this.$route.path != "/balance") {
					this.$router.push("/balance");
				} else {
					this.reload();
				}
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.num = val;
				this.getList();
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.getList();
			},
			handleEdit(index, row) {
				this.$router.push({
					name: "userEdit",
					query: {
						id: row.id,
					},
				});
				//   console.log(index, row);
			},
			updatePass(index, row) {
				this.user_id = row.id;
				this.centerDialogVisible = true;
			},
			liftUser(index, row) {
				this.$confirm('此操作将解禁此用户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('front_user/un_display', {
						id: row.id
					}).then(res => {
						console.log(res);
						if (res.data.status == 200) {
							this.$notify({
								title: '提示',
								message: '操作成功',
								type: 'success'
							});
							this.reload();
						} else {
							this.$notify({
								title: '提示',
								message: res.data.message,
								type: 'error'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			disableUser(index, row) {
				this.$confirm('此操作将删除此用户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$post('front_user/display', {
						id: row.id
					}).then(res => {
						console.log(res);
						if (res.data.status == 200) {
							this.$notify({
								title: '提示',
								message: '操作成功',
								type: 'success'
							});
							this.reload();
						} else {
							this.$notify({
								title: '提示',
								message: res.data.message,
								type: 'error'
							});
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-dialog__body {
		.form_item {
			margin-bottom: 20px;

			span {
				width: 120px;
				display: inline-block;
				vertical-align: middle;
				text-align: right;
			}

			.bit {
				span {
					width: 20px;
					margin-right: 5px;
					margin-top: -5px;
					color: red;
				}
			}

			.el-input {
				width: 300px;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.date-search {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;

			.searchBtn {
				margin-left: 40px;
				padding: 12px 55px;
			}

			.el-input {
				width: 300px;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
